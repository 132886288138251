import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-user/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Showcase your organisations success case studies.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Creating a Case Study</AnchorLink>
    </AnchorLinks>
    <p>{`Case Studies provides a place to show off your companies successful case studies on your KOPE Market profile.`}</p>
    <h2>{`Creating a Case Study`}</h2>
    <p>{`To create a case study:`}</p>
    <ol>
      <li parentName="ol">{`Navigate to the home screen.`}</li>
      <li parentName="ol">{`Click on the `}<inlineCode parentName="li">{`New case study`}</inlineCode>{` button.`}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/21_case-studies-new-case-study-button.png",
      "alt": "21_case-studies-new-case-study-button.png"
    }}></img>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Enter the Basic Info for your case study.`}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/22_case-studies-basic-info.png",
      "alt": "22_case-studies-basic-info.png"
    }}></img>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Next`}</inlineCode>{` to go to the Location screen.`}</li>
      <li parentName="ol">{`Enter the Location info for your case study.`}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/23_case-studies-location.png",
      "alt": "23_case-studies-location.png"
    }}></img>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Next`}</inlineCode>{` to go to the Sectors screen.`}</li>
      <li parentName="ol">{`Enter the Sectors information for your case study.`}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/24_case-studies-sectors.png",
      "alt": "24_case-studies-sectors.png"
    }}></img>
    <ol {...{
      "start": 8
    }}>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Next`}</inlineCode>{` to go to the Roles screen.`}</li>
      <li parentName="ol">{`(Optional) Enter the roles information for your case study.`}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/25_case-studies-roles.png",
      "alt": "25_case-studies-roles.png"
    }}></img>
    <ol {...{
      "start": 10
    }}>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Next`}</inlineCode>{` to go to the Building Systems screen.`}</li>
      <li parentName="ol">{`(Optional) Enter the Building Systems information for your case study.`}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/26_case-studies-building-systems.png",
      "alt": "26_case-studies-building-systems.png"
    }}></img>
    <ol {...{
      "start": 12
    }}>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Next`}</inlineCode>{` to go to the Images screen.`}</li>
      <li parentName="ol">{`(Optional) Upload any relevant images for your case study.`}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/27_case-studies-images.png",
      "alt": "27_case-studies-images.png"
    }}></img>
    <ol {...{
      "start": 14
    }}>
      <li parentName="ol">{`Click `}<inlineCode parentName="li">{`Next`}</inlineCode>{` to go to the Confirmation screen.`}</li>
      <li parentName="ol">{`Confirm and your case study will be uploaded to your profile.`}</li>
    </ol>
    <img {...{
      "src": "https://stkopepublicprod.blob.core.windows.net/public-docs/in-depth/28_case-studies-confirmation.png",
      "alt": "28_case-studies-confirmation.png"
    }}></img>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      